import React from "react";
import styled from "styled-components";
import RowSocial from "../Sections/Contact/rowSocial";
// Assets

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="contactBg">
        <WrapperContainer>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">¿Estas buscando un proveedor de desarrollo  de software de confianza?</h1>{" "}
              <p className="font30">
                <br />
                <br />
               No busques mas, nuestro equipo está listo para ayudarte, no dudes en contactarnos
              </p>{" "}
            </HeaderInfo>{" "}
            <br />
            <br />
            <Advertising>
              <RowSocial txtColor={"#ffffff"} color={"rgba(0, 0, 0, 0.2)"} tag={"Site_"}/>
            </Advertising>
          </div>
        </WrapperContainer>
      </div>{" "}
    </Wrapper>
  );
}

const Advertising = styled.div`
  padding: 40px 0px 40px 0px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1160px) {
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    width: 100%;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;

const WrapperContainer = styled.div`
  padding: 100px 0px 100px 0px;
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    width: 100%;
  }
`;

const HeaderInfo = styled.div`
  h1,
  p {
    color: #ffffff;
  }
  padding: 70px 0 30px 0px;
  text-align: center;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
