import React from "react";
import styled from "styled-components";

export default function OrderRowR({ item, order }) {
  return (
    <RLEquals item={item} order={order}>
      <AddImgWrapp1 className="flexCenter">
        <img  src={item.img} alt="office" />
      </AddImgWrapp1>{" "}
    </RLEquals>
  );
}

const RLEquals = styled.div`
  width: 50%;
  p {
    max-width: 50%;
  }
  order: ${(props) => props.order};
  padding: 0px 0px 0px 0px;
  text-align: center;
  @media (max-width: 860px) {
    width: 80%;
    order: ${(props) => props.item.orderContent};
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }

`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  @media (max-width: 860px) {
    margin: 20px 0px 0px 0px;
  }
  img {
    object-fit: scale-down;
    @media (max-width: 860px) {
      height: 350px;
    }
    display: block;
    margin: auto;
    width: 100%;
    height: 400px;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
