import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
import { Link } from "react-scroll";

// Assets
import OursImage from "../../assets/img/isologo_kodeinnova.png";

export default function Projects() {
  return (
    <Wrapper id="ours">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={OursImage} alt="add" />
                </ImgWrapper>{" "}
              </AddLeftInner>{" "}
            </AddLeft>{" "}
            <AddRight>
            
              <h2 className="font40 extraBold"> Kode Innova Technologie </h2>{" "}
              <h4 className="font15 semiBold">
            
            
          </h4>
              <p className="font18">
               Nuestra experiencia se solidifica en el éxito de crear software a medida para todo tipo de empresas y comercios, con el fin de satisfacer las necesidades del cliente de tal manera que se vea reflejado en el crecimiento potencial para sus negocios. 
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <Link
                    activeClass="false"
                    className="whiteColor"
                    style={{ padding: "10px 0px" }}
                    to="contact"
                    smooth={true}
                    offset={-80}
                  >
                    <FullButton title="Contactanos" txtColor />
                  </Link>{" "}
                </div>{" "}
              </ButtonsRow>{" "}
            </AddRight>{" "}
          </Advertising>{" "}
        </div>{" "}
      </div>{" "}
      <br />
      <br />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 50px 0;
  margin: 0px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  p {
    text-align: justify;
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: center;

  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 10%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
