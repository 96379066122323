import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import Icon192 from "../../assets/img/iso_logo_ki.png"

export default function TopNavbarSocial() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "90px" } : { height: "90px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home">
          <img
          className="icon"
          src={Icon192}
          alt=""
          style={{ height: "55px", width: "220px", margin: "0px 0px" }}
        />
            <h1
              style={{ marginLeft: "15px", marginTop: "10px" }}
              className="font22 extraBoldTwoline"
            >
              KODE INNNOVA <br />{" "}
              <span className="font15 semiBold">
                {" "}
                Servicio de Emergencia y Rescate{" "}
              </span>{" "}
            </h1>{" "}
          </Link>{" "}
        </NavInner>{" "}
      </Wrapper>{" "}
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
