import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { app } from "../../firebaseConfig";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";
import Icon192 from "../../assets/img/iso_logo_ki.png"

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const eventSave = (val) => {
    app.analytics().logEvent(val, {});
  };
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img
          className="icon"
       
        />
 
        </div>{" "}
        <CloseBtn
          onClick={() => {
            eventSave("Site_Close_Drawer");
            toggleSidebar(!sidebarOpen);
          }}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>{" "}
      </SidebarHeader>
      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
            onClick={ () =>{
              eventSave("Site_Inicio_Drawer");
              toggleSidebar(!sidebarOpen);
              }
            }
          >
            Inicio{" "}
          </Link>{" "}
        </li>{" "}
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="service"
            spy={true}
            smooth={true}
            offset={-60}
            onClick={ () =>{
              eventSave("Site_Servicios_Drawer");
              toggleSidebar(!sidebarOpen);
              }
            }
          >
            Servicios{" "}
          </Link>{" "}
        </li>{" "}
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="ours"
            spy={true}
            smooth={true}
            offset={-60}
            onClick={ () =>{
              eventSave("Site_QuienesSomos_Drawer");
              toggleSidebar(!sidebarOpen);
              }
            }
          >
            Quienes Somos{" "}
          </Link>{" "}
        </li>{" "}
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
            onClick={ () =>{
              eventSave("Site_Contactanos_Drawer");
              toggleSidebar(!sidebarOpen);
              }
            }
          >
            Contactanos
          </Link>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 10px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
