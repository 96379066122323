import React, { Component } from "react";
import styled from "styled-components";
import RowClickSocial from "../Contact/rowClickSocial";
import { app } from "../../../firebaseConfig";

import call from "../../../assets/img/icon/phone_call.png";
import ws from "../../../assets/img/icon/whatsapp.png";
import fb from "../../../assets/img/icon/facebook.png";
import ig from "../../../assets/img/icon/instagram.png";
import mail from "../../../assets/img/icon/mail.png";
import web from "../../../assets/img/icon//web.png";


export const ListItemsSocial = function () {
  return [
    {
      index: 0,
      title: "093-920-4087",
      img: call,
      value: "0939204087",
      identifier: "CALL",
    },
    {
      index: 5,
      title: "WhatsApp",
      img: ws,
      value: "988983796",
      identifier: "WS",
      link: "https://api.whatsapp.com/send?phone=593988983796&app=facebook&entry_point=page_cta",
    },
    {
      index: 6,
      title: "kodeinnova@gmail.com",
      img: mail,
      value: "kodeinnova@gmail.com",
      identifier: "MAIL",
      link: "",
    },
  ];
};

class RowSocial extends Component {
 

  open(item) {
    app
      .analytics()
      .logEvent(this.props.tag+ `${item.identifier}`, {});

    if (item.identifier == "CALL" || item.identifier == "CALL2") {
      this.launch(`tel:${item.value}`);
    } else if (
      item.identifier == "WS" ||
      item.identifier == "FB" ||
      item.identifier == "IG" ||
      item.identifier == "WEB"
    ) {
      this.launch(item.link);
    } else {
      this.launch(`mailto:${item.value}`);
    }
  }

  launch(val) {
    const win = window.open(val, "_blank");
    if (win != null) {
      win.focus();
    }
  }


  render() {
    return (
      <React.Fragment>
        <Advertising>
          <ul className="list-group  ">
            {(this.props.tag == "Site_" ? ListItemsSocial().filter(i => i.identifier != "WEB") : ListItemsSocial()).map((val) => (
             
              <RowClickSocial key={val.index} color={this.props.color} txtColor={this.props.txtColor} item={val} action={() => this.open(val)} />
            ))}
          </ul>
        </Advertising>
      </React.Fragment>
    );
  }
}
export default RowSocial;

const Advertising = styled.div`
  @media (max-width: 1160px) {
  }


  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
