import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import Icon192 from "../../assets/img/iso_logo_ki.png"

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="whiteColor">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
             <img
          className="icon"
          src={Icon192}
          alt=""
          style={{ height: "55px", width: "220px", margin: "0px 0px" }}
        />
           
            </Link>
            <StyleP className="darkColor font13">
             
              ©{getCurrentYear()} -
              <span className="darkColor font13"> Kode Innova Technologie, </span> All Right
              Reserved{" "}
            </StyleP>
           
          </InnerWrapper>{" "}
        </div>{" "}
      </div>{" "}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
