import React, { Component } from "react";
import ProjectBox from "../../Elements/ProjectBox";


import movildev from "../../../assets/img/services/appdeveloper_1.jpg";
import webdev from "../../../assets/img/services/webdeveloper_2.jpg";
import testing from "../../../assets/img/services/testing.jpg";
import ux from "../../../assets/img/services/ux.jpg.avif";
import optimization from "../../../assets/img/services/optimization.jpg";
import support from "../../../assets/img/services/support.jpg.avif";

import { app } from "../../../firebaseConfig";
class ImageListService extends Component {

  eventSave = (val) => {
    app.analytics().logEvent(val, {});
  };


  state = {
    listitems: [
      { index: 0, title: "Desarrollo Movil", img: movildev, body: "dsdsdssd" },
      { index: 1, title: "Desarrollo Web", img: webdev, body: "dsdsdssd" },
      {
        index: 2,
        title: "Testing",
        img: testing,
        body: "dsdsdssd",
      },
      { index: 3, title: "UX", img: ux, body: "dsdsdssd" },
      { index: 4, title: "Optimizacion", img: optimization, body: "dsdsdssd" },
      { index: 5, title: "Soporte", img: support, body: "dsdsdssd" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        {this.state.listitems.map((val) => (
          <div key={val.index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <ProjectBox  img={val.img} action={() => this.eventSave("Site_"+val.title)} />
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default ImageListService;
