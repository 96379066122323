import firebase from "firebase/compat/app"
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/firestore";


export const app = firebase.initializeApp({
  apiKey: "AIzaSyASR1oD6y94Clf0UnxIWH2uoFRwACVH3SI",
  authDomain: "kodeinnova-7e6d2.firebaseapp.com",
  projectId: "kodeinnova-7e6d2",
  storageBucket: "kodeinnova-7e6d2.appspot.com",
  messagingSenderId: "1044502633145",
  appId: "1:1044502633145:web:c5fb6fa419bb9205ee9968",
  measurementId: "G-50RDCCJC3Q"
});

