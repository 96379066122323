import React, { Component } from "react";
import RowOrderService from "./orderRow";
import OrderRowR from "./orderRowR";
import styled from "styled-components";
import ClientLogo01 from "../../../assets/img/clients/logo01.svg";
import ClientLogo02 from "../../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../../assets/img/clients/logo03.svg";
import dweb from "../../../assets/img/services/dweb.jpg";
import dmovil from "../../../assets/img/services/movil.jpg";
import mantiance from "../../../assets/img/services/maintenance.jpg";
import brand from "../../../assets/img/services/brandmanager_1.jpg";

class RowService extends Component {
  state = {
    listitems: [
      {
        index: 0,
        orderImg: "2",
        orderContent: "1",
        title: "Desarrollo de Aplicaciones Moviles",
        img: dmovil,
        body: "Desarrollamos aplicaciones moviles nativas para un maximo rendimiento en los telefonos moviles.",
      },
      {
        index: 1,
        orderImg: "1",
        orderContent: "2",
        title: "Desarrollo de Aplicaciones Web",
        img: dweb,
        body: "Desarrollamos aplicaciones web con diseño minimalistico para todo tipo de industrias.",
      },
      {
        index: 2,
        orderImg: "2",
        orderContent: "1",
        title: "Mantenimiento y Soporte",
        img: mantiance,
        body: "Nos aseguramos de que las soluciones de software de nuestros clientes funcionen a la perfección. Nuestro equipo se encarga de los servicios de mantenimiento continuo para que nuestros socios puedan centrarse únicamente.",
      },
      {
        index: 3,
        orderImg: "1",
        orderContent: "2",
        title: "Branding",
        img: brand,
        body: "Nuestro equipo de estrategia de marca sabe que su marca no es solo elegir los colores y el logotipo adecuados. Es toda la identidad de tu empresa. Refinar o establecer.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <ul className="list-group  container">
          {" "}
          {this.state.listitems.map((val) => (
            <Advertising key={val.index} className="flexSpaceCenter">
              <RowOrderService
                item={val}
                order={val.orderImg}
              ></RowOrderService>{" "}
              <OrderRowR item={val} order={val.orderContent}>
                {" "}
              </OrderRowR>{" "}
            </Advertising>
          ))}{" "}
        </ul>{" "}
      </React.Fragment>
    );
  }
}
export default RowService;

const Advertising = styled.div`
  margin: 0px 0px 70px 0;
  padding: 0px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 10px 0 0px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 50px 0;
    margin: 0px 0 0px 0;
  }
`;
